@use "components/variables";
@use "components/tab-content/tab-content";
@use "components/content-templates.scss"; // not LT

#sass {
    @if variables.$site-euronics-ee{
        header {
            background-color: variables.$white;
            border-top: 1px solid variables.$neutral-lighter-25;
            
            .header {
                &__pre-title {
                    color: variables.$primary;
                    font-weight: variables.$font-weight-bold;
                    font-size: variables.$font-size-sm;
                }
    
                &__container {
                    grid-template-areas:
                    "pre-title image"
                    "title image"
                    "buttons image"
                    "footer__phone image";
                }
                
                &__image {
                    content: url('/images/sass/sass-header-image.jpg');
                    grid-area: image;
                }
            }
            
            h1 {
                color: variables.$primary;
            }
            .footer__phone {
                color: variables.$gray-500;
                font-weight: variables.$font-weight-normal;
                font-size: variables.$font-size-lg;
            }
    
            a{
                color: variables.$primary;
                font-weight: variables.$font-weight-bold;
            }
            
            .button {
                width: fit-content;
                padding: 0 50px;
                margin-bottom: 20px;
                
                &__text {
                    font-size: variables.$font-size-sm;
                    padding: 0;
                }
            }
        }
        
        .title{
            padding-bottom: 40px;
            max-width: 800px;
            justify-self: center;
            margin: auto;
        }

        .price-list-block{
            width: 100%;
            max-width: 800px;
            background-color: variables.$white;
            border-radius: 8px;
            justify-items: center;
            justify-self: center;
            margin: auto;
            text-align: center;
            
            >*:not(.button){
                padding: 30px 20px;
            }
            .price-list-block__info {
                display: flex;
                align-items: center;
                max-width: 90%;
                border-top: 1px solid variables.$neutral-lighter-25;
                padding-top: 10px;
                margin-top: 30px;
                font-size: 16px;
                line-height: 24px;
                text-align: left;
            }
            
            .text{
                text-align: -webkit-center;
                font-size: 16px;
                line-height: 24px;
            }

            h3 {
                font-size: variables.$font-size-lg;
                font-weight: variables.$font-weight-bold;
                padding: 0;
            }
        }

        h2:not(.header__statistics h2) {
            margin: 0 16px;
            line-height: 34px;
            color: variables.$primary;
            font-size: variables.$font-size-2xl;
            font-weight: variables.$font-weight-bold;
            text-align: center;
        }
        
        #_IncidentProductInfo h2{
            text-align: left;
            font-size: variables.$font-size-lg;
            margin: auto;
        }
        
        section.sass__results .tabs-menu__list h2{
            font-size: variables.$font-size-lg;
            margin: auto;
        }
        
        article.spare-parts .content {
            padding: 20px 36px;
            .buttons .button{
                width: unset;
                padding: 2px 40px;
            }
        }
        
        
        @media (max-width: variables.$responsive-break - 1) {
            header .header__container {
                grid-template-columns: 1fr;
                grid-template-areas:
                    "pre-title"
                    "title"
                    "image"
                    "buttons"
                    "footer__phone";
                justify-items: center;
                
                .image{
                    max-width: 100%;
                }
                
                >*{
                    margin: 10px 0;
                }
            }
            
            .title{
                margin: auto auto auto 0;
            }

            h2:not(.header__statistics h2) {
                text-align: left;
                margin: 0;
                padding: 10px 10px 10px 20px;
            }
    
            .question-info,.price-list-info{
                margin: 40px 20px;
                padding: 10px 20px 20px 20px;
                .image {
                    align-self: start;
                }
            }
            
            .price-list{
                &-block{
                    .text {
                        text-align: left;
                    }
                    
                    a {
                        padding: 0;
                        width: 90%;
                        display: block;
                        text-align: center;
                        max-width: 300px;
                        margin: auto;
                    }
                    
                    .price-list-block__info{
                        align-items: unset;
                    }

                    h3 {
                        text-align: left;
                    }
                }
                
                &-info{
                    margin: 20px 0 0 0;
                    padding: 10px;
                }
            }

            .button{
                width: -webkit-fill-available;
            }
            
            .w-100 .sass-form{
                margin: auto;
                h2,h3{
                    text-align: left;
                }
            }

            article.spare-parts {
                .image-repairman {
                    height: 100%;
                }
                
                .text h2{
                    padding-left: 36px;
                }
            }

            .repair-help .repair-help__header h2{
                padding-left: 0;
            }
        }
        
        @media (max-width: variables.$mobile-break - 1) {
            .repair-help .text{
                .repair-help__header {
                    flex-direction: column-reverse;
                    margin: 0;
                    .icon{
                        max-height: 70px;
                    }
                    h2{
                        padding: 10px 20px 30px 20px;
                        width: unset;
                    }
                }
                .content {
                    padding: 10px 20px 30px 20px;
                }
            }
            article.spare-parts {
                grid-template-rows: 65vw 1fr;
                .image-repairman {
                    margin:auto;
                    justify-self: center;
                }

                .text{
                    h2{
                        padding-left: 20px;
                    }
                    .content {
                        padding: 20px;
                    }
                }
            }
            
            .questions .question .text h3{
                text-align: left;
            }

            .question-info,.price-list-info,.price-list-block__info{
                .icon {
                    padding: 10px;
                }
            }
        }
    }
    
    .sass__results {
        padding: 0;

        ul,
        section {
            padding: 0;
        }

        h1, h2, h3, h4, h5, h6 {
            color: variables.$primary;
            font-weight: 400;
        }
        
        .tabs-menu__link {
            padding: 10px;
        }
    }

    .section--gray {
        background: variables.$neutral-lighter-10;
        @if variables.$site-euronics-ee {
            background: variables.$neutral-lighter-25;
        }
    }
    
    .modest-text {
        color: variables.$neutral-dark;
        margin-top: 15px;
    }

    .wide-white-box {
        background-color: variables.$white;
        padding: 25px;

        &__menu {
            padding-bottom: 0;
        }
    }

    .wide-gray-box {
        background-color: variables.$neutral-lighter-25;
        padding: 50px 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        
        .w-80{
            width: 80%;
        }
    }

    .sass-form {
        max-width: 660px;
        margin: auto;

        @if variables.$site-euronics-ee {
            margin-left: 120px;
        }

        h2 {
            margin: 0 16px;
            font-weight: 400;
            font-size: 21px;
            line-height: 24px;
            color: variables.$primary;
            @if variables.$site-euronics-ee {
                font-size: variables.$font-size-2xl;
                font-weight: variables.$font-weight-bold;
                text-align: center;
                line-height: 32px;
            }
        }

        @if variables.$site-euronics-ee {
            h3 {
                font-size: variables.$font-size-lg;
                margin: 20px 0;
                padding: 0;
                color: variables.$primary;
                text-align: center;
                font-weight: variables.$font-weight-normal;
            }
        }

        .form-inputs {
            padding: 15px 0;

            .col {
                flex-wrap: wrap;
                padding: 16px;

                .field-validation-valid {
                    display: inline-block;
                }
            }

            label {
                margin: 10px 0;
                padding: 0;
                color: variables.$primary;
                font-size: 16px;
            }

            #Reference {
                text-transform: uppercase;
            }
        }

        button#SearchIncident {
            max-width: 400px;
            display: block;
            margin: auto;
        }
    }


    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 600px;
        font-size: 16px;
    }

    .grid-label {
        color: variables.$primary;
    }

    .grid-content {
        color: variables.$primary;
        font-weight: 600;
    }

    .alert {
        padding: 20px 25px;
    }

    .history-item {
        background-color: variables.$white;
        padding: 25px;
        margin: 25px 0;

        .row {
            padding-bottom: 0;
        }
        
        .col {
            padding: 0 0 0 16px;
        }
        
        .actor {
            color: variables.$neutral-dark;
        }
    }

    .history-item {
        display: grid;
    }

    @media(max-width: variables.$responsive-break - 1) {
        .icon-tools {
            display: none;
        }
        
        .tabs-menu__item {
            font-size: 12px;
        }

        .sass-form {
            
            h2 {
                margin: 0;
                padding: 0;
            }
            .form-inputs {
                flex-wrap: wrap;
                display: inline-block;

                .col {
                    flex-basis: fit-content;
                    padding: 0;
                }
            }
        }
    }

    .reCaptcha {
        display: block;
        max-width: 300px;
        margin: 10px auto 20px auto;
        height: 75px;
    }
    
    .repair-help{
        background: #FFFFFF;
        border: 1px solid #D6D8E1;
        border-radius: 8px;
        overflow: hidden;
        min-height: 320px;
        @if variables.$site-euronics-ee {
            min-height: 230px;
        }
        margin-bottom: 40px;
        
        &__header{
            display: flex;
            @if variables.$site-euronics-ee {
                border-bottom: 1px solid variables.$neutral-lighter-25;
                margin: 0 36px;
            }
            h2{
                width: 100%;
                color: variables.$primary;
                font-weight: variables.$font-weight-bold;
                font-size: variables.$font-size-2xl;
                line-height: 24px;
                padding: 40px 30px;
                align-content: center;
            }
        }
        @if variables.$site-euronics-ee {
            .content {
                padding: 20px 36px;
            }
        }
    }
    
    article h2{
        font-weight: variables.$font-weight-bold;
        font-size: variables.$font-size-2xl;
        @if variables.$site-euronics-ee {
            border-bottom-color: variables.$neutral-lighter-25;
        }
    }
}

.icon-repair-help{
    content: url('/images/sass/repair-help.svg');
    padding: 20px 50px;
}

.icon-product-pointing{
    content: url('/images/sass/product-pointing.svg');
}

.icon-repair-info{
    content: url('/images/sass/repair-info.svg');
}

.icon-product-ready{
    content: url('/images/sass/product-ready.svg');
}

.icon-tools {
    content: url('/images/sass/tools.svg');
}

.icon-broken-magnifier {
    content: url('/images/sass/broken_magnifier.svg');
}

.image-repairman {
    content: url('/images/sass/repairman.jpg');
    grid-area: image;
}

.price-offer {
    &__title a{
        text-decoration: underline;
        font-weight: variables.$font-weight-normal;
        padding-left: 30px;
    }
    
    h2 {
        padding-top: 0;
    }
    
    &__header {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 16px 150px 0;
        
        .invoice-date-label {
            color: variables.$neutral;
            grid-column: 1;
            grid-row: 1;
        }
        
        .invoice-date {
            grid-column: 1;
            grid-row: 2;
        }
        
        .valid-until-label {
            color: variables.$neutral;
            grid-column: 2;
            grid-row: 1;
            text-align: right;
        }
        
        .valid-until {
            grid-column: 2;
            grid-row: 2;
            text-align: right;
        }
    }
    
    &-variant {
        background-color: variables.$white;
        padding: 16px;
        margin: 30px;
        font-size: variables.$font-size-sm;

        &__title {
            color: variables.$primary;
            font-weight: variables.$font-weight-bold;
        }
        
        &__total {
            font-weight: variables.$font-weight-bold;
            color: variables.$primary;
            padding-top: 16px;
        }
        
        &-grid {
            display: grid;
            grid-template-columns: 80% 20%;
            padding: 0 50px;
            
            &__header {
                color: variables.$neutral;
                padding-top: 16px;
            }
            
            &-container {
                display: grid;
                grid-template-columns: 70% 10% 10% 10%;
                padding: 0 50px;
            }
        }
    }
    
    &-confirm {
        display: flex;
        justify-content: center;
    }
}

@media (min-width: variables.$responsive-break) {
    .price-offer {
        &__title {
            display: flex;
            align-items: end;
            justify-content: space-between;
            padding: 0 50px;
        }

        &-variant__title, &-variant__description {
            padding: 0 50px;
        }
    }
}

@media (max-width: variables.$responsive-break - 1) {
    .price-offer {
        &__header {
            padding: 16px 50px 0;
        }
        
        &-variant {
            font-size: variables.$font-size-xs;
            
            &-grid {
                grid-template-columns: 60% 40%;
                padding: 0;

                &-container {
                    grid-template-columns: 40% 20% 20% 20%;
                    padding: 0;
                }
            }
        }
    }
}

.text-right {
    text-align: right;
}

@if variables.$site-avi {
    .buttons{
        justify-content: center;
    }
}